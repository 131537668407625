import React, { Suspense, useState } from "react"
import axios from "axios"
import { Cookies } from "react-cookie"
import { HashRouter, Route, Routes } from "react-router-dom"
import { ExclamationTriangle, EmojiSmileUpsideDown } from "react-bootstrap-icons"
import { CContainer, CRow, CCol, CCard, CCardBody, CAlert } from "@coreui/react"
import "./scss/style.scss"

const App = () => {
  const DefaultLayout = React.lazy(() => import("./components/DefaultLayout"))
  const cookies = new Cookies(), [flash, setFlash] = useState("")
  const searchParams = new URLSearchParams(window.location.search)
  const source = searchParams.get("source")
  const notAuthorized = "Your Google account is not authorized on this platform, please contact Revota Support for authorize."
  const serverProblem = "There is a problem on the server, please inform this to Revota Support."
  const serverUpdate = "There is an update on the Revota server, please re-login."
  const googleLogin = e => {
    e.preventDefault()
    cookies.remove(process.env.REACT_APP_COOKIE_NAME)
    window.open(`${process.env.REACT_APP_SSO_URL}auth/google`, "_self")
  }

  if (!cookies.get(process.env.REACT_APP_COOKIE_NAME) && source === "google") {
    axios.get(`${process.env.REACT_APP_SSO_URL}auth/login/success`, {
      withCredentials: true, headers: { "Access-Control-Allow-Credentials": true }
    }).then(res => {
      if (res.headers.get("content-type").includes("application/json")) {
        const photo = res?.data.photos[0]?.value
        axios.post(`${process.env.REACT_APP_DATA_URL}google-token`,
          { email: res?.data.emails[0]?.value },
          { headers: { "Content-Type": "application/json" } }
        ).then(response => {
          if (!response.data.statusCode) {
            if (response.data.token && response.data.token !== null) {
              response.data.photo = photo
              axios.post(`${process.env.REACT_APP_DATA_URL}encrypt`, response.data,
                { headers: { "Content-Type": "application/json" } }
              ).then(encrypted => {
                cookies.set(process.env.REACT_APP_COOKIE_NAME, encrypted.data, { maxAge: 60000 })
                window.location.assign(process.env.REACT_APP_BASE_URL)
              }).catch(() => setFlash(serverProblem))
            } else { setFlash(notAuthorized) }
          } else { setFlash(serverProblem) }
        }).catch(() => setFlash(serverProblem))
      } else { setFlash(serverProblem) }
    }).catch(() => setFlash(serverProblem))

    return (
      <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer className="pt-5">
          <CRow className="justify-content-center">
            <CCol md={9} lg={7} xl={5} className="text-center">
              <img src={`${process.env.REACT_APP_BASE_URL}login_logo.png`} />
            </CCol>
          </CRow>
          <CRow className="justify-content-center">
            <CCol md={9} lg={7} xl={5}>
              <CCard className="p-4 pt-0 border-0">
                <CCardBody className="text-center">
                  <h1 className="mb-3">Revota<br />Gross Transaction Value</h1>
                  {flash !== "" ?
                    <>
                      <CAlert dismissible color="danger" variant="solid" className="fs-4 mb-4 alert-login-custom">
                        <ExclamationTriangle size={25} /><br />{flash}
                      </CAlert>
                      <a className="btn btn-lg fw-bold text-muted custom-link google-button" onClick={googleLogin}>
                        <img src={`${process.env.REACT_APP_BASE_URL}google_logo.png`} className="me-2" />Sign in with Google
                      </a>
                    </> : <div className="d-flex justify-content-center mt-4"><div className="spinner-border"></div></div>
                  }
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
          <CRow className="justify-content-center text-center text-muted">
            <span>
              &copy; {new Date().getFullYear()}
              <a href="https://revota.id" target="_blank" className="text-muted ms-1">Revota.</a> All rights reserved.
            </span>
          </CRow>
        </CContainer>
      </div>
    )
  } else if (!cookies.get(process.env.REACT_APP_COOKIE_NAME) && source !== "google") {
    return (
      <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer className="pt-5">
          <CRow className="justify-content-center">
            <CCol md={9} lg={7} xl={5} className="text-center">
              <img src={`${process.env.REACT_APP_BASE_URL}login_logo.png`} />
            </CCol>
          </CRow>
          <CRow className="justify-content-center">
            <CCol md={9} lg={7} xl={5}>
              <CCard className="p-4 pt-0 border-0">
                <CCardBody className="text-center">
                  <h1 className="mb-3">Revota<br />Gross Transaction Value</h1>
                  {source === "server-update" ?
                    <CAlert dismissible color="info" variant="solid" className="fs-4 mb-4 alert-login-custom">
                      <EmojiSmileUpsideDown size={25} /><br />{serverUpdate}
                    </CAlert> : ""
                  }
                  <a href="#" className="btn btn-lg mt-2 fw-bold text-muted custom-link google-button" onClick={googleLogin}>
                    <img src={`${process.env.REACT_APP_BASE_URL}google_logo.png`} className="me-2" />Sign in with Google
                  </a>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
          <CRow className="justify-content-center text-center text-muted">
            <span>
              &copy; {new Date().getFullYear()}
              <a href="https://revota.id" target="_blank" className="text-muted ms-1">Revota.</a> All rights reserved.
            </span>
          </CRow>
        </CContainer>
      </div>
    )
  } else {
    return (
      <HashRouter>
        <Suspense><Routes><Route path="*" name="Home" element={<DefaultLayout />} /></Routes></Suspense>
      </HashRouter>
    )
  }
}

export default App